import React, { useEffect } from 'react';
import ContactPageTemplate from '../../components/ContactPageTemplate';

export default () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-817271415/xUSxCPfNvt0BEPek2oUD',
      });
    }
  }, []);

  return (
    <ContactPageTemplate>
      <div className="card is-centered">
        <div className="card-content  has-text-centered">
          <div className="title">Thank you!</div>
          <p>
            Thank you for connecting with us. We will respond to you shortly.
          </p>
        </div>
      </div>
    </ContactPageTemplate>
  );
};
